<template>
	<v-row class="mt-4">
		<v-col class="pl-5 pr-5">
			<span class="overline">{{ $t("results.umsetzung_progress") }}</span>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-progress-linear
						v-on="on"
						height="13"
						color="green"
						:value="n"
					>
					</v-progress-linear>
				</template>
				{{ label }}
			</v-tooltip>
		</v-col>
	</v-row>
</template>

<script>
// Displays a progress bar for the umsetzung score
//@group Analysis
export default {
	name: "UmsetzungBar",
	props: {
		// the current percentage
		value: { type: [Number, String] },
	},
	computed: {
		n() {
			return new Number(this.value);
		},
		label() {
			if (isNaN(this.value)) {
				return "0%";
			}
			return (this.value || "0") + "%";
		},
	},
};
</script>
