<template>
	<v-sheet>
		<umsetzung-bar v-model="umsetzung"></umsetzung-bar>
		<v-row>
			<v-toolbar color="transparent" flat>
				<v-btn-toggle v-model="seriesAggregation" class="mr-3" mandatory>
					<v-btn value="average">{{ $t("results.average") }}</v-btn>
					<v-btn value="departments">Bereiche</v-btn>
					<v-btn value="parts">{{ $t("results.goal_groups") }}</v-btn>
				</v-btn-toggle>
				<v-btn-toggle v-model="visualisationType" mandatory>
					<v-btn icon value="chart"><v-icon>mdi-chart-bar</v-icon></v-btn>
					<v-btn icon value="table"><v-icon>mdi-table</v-icon></v-btn>
				</v-btn-toggle>
				<chart-types
					v-if="visualisationType == 'chart'"
					@change="(e) => (chartType = e)"
				></chart-types>
				<v-spacer></v-spacer>
				<save-image
					v-if="visualisationType == 'chart'"
					id="chart"
				></save-image>
				<export
					v-else
					icon
					:headers="exportColumns"
					:rows="tableRows"
					name="vis"
				></export>
			</v-toolbar>
		</v-row>
		<v-row>
			<v-col cols="12" sm="4" class="mt-4">
				<v-item-group v-model="selectedSeries" multiple>
					<series-selection-panel
						v-for="s in series"
						:key="s.id"
						v-bind="s"
						:color="seriesColors[s.id]"
						@setColor="(v) => setColor(s.id, v)"
					></series-selection-panel>
				</v-item-group>
				<v-item-group v-model="selectedParts" multiple>
					<v-expansion-panels flat>
						<data-selection-panel
							v-for="id in departmentOrder"
							:key="id"
							:id="id"
							:selected="selectedParts"
							@select="toggleDepartment"
						></data-selection-panel>
					</v-expansion-panels>
				</v-item-group>
				<v-btn text @click="selectAll" small>{{
					$t("config.goals.select_all")
				}}</v-btn>
				<v-btn
					text
					@click="selectedParts = []"
					small
					v-if="selectedParts.length > 0"
					>{{ $t("config.goals.deselect_all") }}</v-btn
				>
			</v-col>
			<v-col cols="12" sm="8" class="mt-12">
				<Chart
					:loading="loading"
					id="chart"
					v-if="visualisationType == 'chart'"
					:type="chartType"
					v-bind="output"
					:options="chartType == 'HBarChart' ? hOptions : vOptions"
				></Chart>
				<v-data-table
					v-else
					:items="tableRows"
					:headers="tableColumns"
				></v-data-table>
			</v-col>
		</v-row>
		<v-row v-if="!loading">
			<handlungsbedarf :data="myGoalScores"></handlungsbedarf>
		</v-row>
	</v-sheet>
</template>

<style lang="less">
@import "./../assets/css/results/charts.less";
</style>

<script type="text/javascript">
import firebase from "firebase";
import Vue from "vue";
import Vuex from "vuex";
import ChartTypes from "@c/survey/results/ChartTypes";
import Chart from "@c/survey/results/Chart";
import UmsetzungBar from "@c/survey/results/UmsetzungBar";
import SeriesSelectionPanel from "@c/survey/results/SeriesSelectionPanel";
import DataSelectionPanel from "@c/survey/results/DataSelectionPanel";
import SaveImage from "@c/downloads/SaveImage";
import Export from "@c/downloads/Export";
import Handlungsbedarf from "@c/survey/results/Handlungsbedarf";

export default {
	name: "Analysis",
	props: {},
	data: () => {
		return {
			loading: false,
			seriesAggregation: "average",
			visualisationType: "chart",
			chartType: "BarChart",
			selectedParts: [],
			selectedSeries: ["s1"],
			allData: {},
			vOptions: {
				scales: {
					yAxes: [
						{
							display: true,
							scaleLabel: {
								display: true,
								labelString: "Handlungsbedarf",
							},
							ticks: {
								max: 5,
								beginAtZero: true, // minimum value will be 0.
							},
						},
					],
				},
			},
			hOptions: {
				scales: {
					xAxes: [
						{
							display: true,
							scaleLabel: {
								display: true,
								labelString: "Handlungsbedarf",
							},
							ticks: {
								max: 5,
								beginAtZero: true, // minimum value will be 0.
							},
						},
					],
				},
			},
			seriesColors: {
				s1: "",
				s2: "",
			},
		};
	},
	components: {
		Chart,
		ChartTypes,
		DataSelectionPanel,
		SeriesSelectionPanel,
		Export,
		Handlungsbedarf,
		UmsetzungBar,
		SaveImage,
	},
	computed: {
		...Vuex.mapState({
			assessment: (state) => state.assessment.data,
			goals: (state) => state.goals.data,
			responseData: (state) => state.responseData.data,
			departments: (state) => state.departments.data,
			sectionParts: (state) => state.sectionParts.data,
			sectionResponses: (state) => state.sectionResponses.data,
			goalGroups: (state) => state.goalGroups.data,
		}),
		series() {
			return [
				{
					label: this.$t("results.your_score"),
					id: "s1",
					source: "own_data",
				},
			];
		},
		questions() {
			var questions = this.$store.getters["questions/defaultOrder"];
			return questions.filter((q) => q.scored);
		},
		umsetzung() {
			var self = this;
			var scores = self.dataPoints.map((a) =>
				self.mwsurveyutilities.umsetzung(a)
			);
			scores = scores.filter( s => typeof s !== "undefined");
			return this.mwsurveyutilities.averageScore(scores);
		},
		questionLength() {
			return this.questions.length;
		},
		departmentOrder() {
			return this.$store.getters["departments/canReadOrder"];
		},
		partOrder() {
			return this.$store.getters.defaultPartOrder;
		},
		responseMap() {
			return this.$store.getters.responseMap;
		},
		labelMap() {
			const self = this;
			var labels = {
				average: self.$t("results.average"),
			};
			Object.keys(self.responseMap).forEach((d) => {
				labels[self.responseMap[d].id] = self.departments[d].name;
			});
			Object.values(self.sectionParts).forEach((p) => {
				var g = self.goalGroups[p.goalGroup];
				labels[p.goalGroup] = g.name;
			});
			return labels;
		},
		haveConfig() {
			return this.$store.getters["organisation/haveConfig"];
		},
		canAccessAssessment() {
			return this.$store.getters["assessment/canAccessAssessment"];
		},
		dataPoints() {
			var self = this;
			var dataPoints = Object.values(self.responseData)
				.filter((g) =>{ 
					let department = self.sectionResponses[g.sectionResponse].department;
					let partId = self.makePartId(department, g.part);
					return self.selectedParts.includes(partId)
				})
				.map((dp) => {
					dp.goalGroup = self.sectionParts[dp.part].goalGroup;
					return dp;
				});
			return dataPoints;
		},
		myGoalScores() {
			var self = this;
			var scores = Object.fromEntries(
				self.dataPoints.map((dataPoint) => {
					return [
						dataPoint.id,
						self.mwsurveyutilities.calculateScore(
							self.questions,
							dataPoint.data
						),
					];
				})
			);
			return scores;
		},
		tableColumns() {
			var columns = [{ text: "Item", value: "item" }];
			this.selectedSeries.forEach((s) => {
				var series = this.series.find((a) => a.id == s);
				columns.push({ text: series.label, value: s });
			});
			return columns;
		},
		exportColumns() {
			var columns = this.tableColumns;
			return columns.map((c) => {
				return { field: c.value, label: c.text };
			});
		},
		tableRows() {
			var self = this;
			var data = [];
			self.output.labels.forEach((label, i) => {
				var item = { item: label };
				self.output.data.forEach((d) => {
					item[d.id] = d.data[i] || "-";
				});
				data.push(item);
			});
			return data;
		},
		output() {
			const self = this;

			var datasets = {
				data: [],
				labels: [],
			};

			if (!self.selectedParts.length || !self.selectedSeries.length) {
				return datasets;
			}

			self.selectedSeries.forEach((seriesId) => {
				var series = self.series.find((a) => a.id == seriesId);
				var input;
				if (series.source == "own_data") {
					input = self.myGoalScores;
				} else {
					input = self.allData;
				}

				var output = self.calculateSeriesData(input);
				datasets.labels = Object.keys(output);

				datasets.data.push({
					backgroundColor: self.seriesColors[series.id] || "grey",
					label: series.label || "value",
					id: series.id,
					data: Object.values(output) || [],
				});
			});

			datasets.labels = self.sortLabels(datasets.labels);
			datasets.labels = datasets.labels.map((l) => self.labelMap[l] || l);

			return datasets;
		},
		includeExternalData() {
			var self = this;
			var include = self.series.find(
				(a) => self.selectedSeries.includes(a.id) && a.source == "all_data"
			);
			if (include) {
				return true;
			}
			return false;
		},
		sortOrder() {
			const self = this;
			let order = [];
			if (self.seriesAggregation == "departments") {
				order = self.departmentOrder;
			}
			if (self.seriesAggregation == "parts") {
				order = Object.values(self.partOrder)
					.flatMap((p) => p)
					.map((p) => self.sectionParts[p].goalGroup);
			}
			return order;
		},
	},
	methods: {
		makePartId(department, part){
			return `${department}-${part}`;
		},
		splitPartId(id){
			let department = id.split("-")[0]
			let part = id.split("-")[1]
			return {part, department}
		},
		async getData(dataPoint) {
			var functions = firebase.app().functions("europe-west1");
			var getAllData = functions.httpsCallable("getResponseData");
			return await getAllData(dataPoint);
		},
		sortLabels(arr) {
			const self = this;
			let order = arr.sort((a, b) => {
				var x = self.sortOrder.indexOf(a);
				var y = self.sortOrder.indexOf(b);
				return x < y ? -1 : x > y ? 1 : 0;
			});
			return order;
		},
		selectAll() {
			const self = this;
			Object.keys(self.departments).forEach((d) => {
				self.toggleDepartment(d, true);
			});
			// this.selectedParts = Object.keys(this.sectionParts)
		},
		setColor(id, color) {
			Vue.set(this.seriesColors, id, color);
		},
		toggleDepartment(department, show) {
			var self = this;
			var section = self.departments[department].section;
			var parts = self.partOrder[section];
			if (show) {
				parts.forEach((part) => {
					let id = self.makePartId(department, part);
					if (!self.selectedParts.includes(id)) {
						self.selectedParts.push(id);
					}
				});
			} else {
				self.selectedParts = self.selectedParts.filter(
					(ids) => {
						let id = self.splitPartId(ids);
						return !parts.includes(id.part)
					}
				);
			}
		},
		calculateSeriesData(input) {
			const self = this;
			var scores = {};

			if (self.seriesAggregation == "average") {
				scores.average = Object.values(input);
			} else {
				self.dataPoints.forEach((dp) => {
					var key;
					if (self.seriesAggregation == "departments") {
						key = dp.sectionResponse;
					}
					if (self.seriesAggregation == "parts") {
						key = dp.goalGroup;
					}
					if (!scores[key]) {
						scores[key] = [];
					}
					scores[key].push(input[dp.id]);
				});
			}

			var average = {};

			for (var key in scores) {
				let theseScores = scores[key];
				theseScores = theseScores.filter((a) => a !== null);
				average[key] = self.mwsurveyutilities.averageScore(theseScores);
			}
			return average;
		},
		getExternalData() {
			var self = this;
			// check if we are including data from all organisations
			if (self.includeExternalData) {
				var dataPoints = self.dataPoints;
				// for every included goal, grab the data
				dataPoints.forEach((dataPoint) => {
					// get new data only
					if (!self.allData[dataPoint.id]) {
						self.getData(dataPoint).then((result) => {
							// calculate the scores
							var scores = result.data
								.map((response) => {
									return self.mwsurveyutilities.calculateScore(
										self.questions,
										response
									);
								})
								.filter((score) => score !== 0);
							// calculate the average and write to the data object
							self.allData[
								dataPoint.id
							] = self.mwsurveyutilities.averageScore(scores);
						});
					}
				});
			}
			return;
		},
	},
	watch: {
		dataPoints() {
			this.getExternalData();
		},
		selectedSeries() {
			this.getExternalData();
		},
	},
	provide() {
		return {
			makePartId: this.makePartId,
			splitPartId: this.splitPartId
		}
	},
	created() {
		this.seriesColors.s1 = this.$vuetify.theme.themes.light.primary;
		this.seriesColors.s2 = this.$vuetify.theme.themes.light.accent;
	},
};

//
</script>
"
