<template>
    <v-item v-slot:default="{ active, toggle }" :value="id">
        <v-chip class="mb-2 pa-3" :color="active ? color : 'white'" :dark="active"  @click="toggle"  :class="{active: active}">
            <SelectColor :color="color" @change="(v) => $emit('setColor', v)"></SelectColor>
            <span class="pr-5 label">{{label}}</span>
        </v-chip>
    </v-item>
</template>

<style lang="less" scoped>
    .v-chip {
        span.label {
            text-decoration: line-through;
        }
        &.active {
            span.label {
                text-decoration: none;
            }
        }
    }
</style>

<script type="text/javascript">
    import SelectColor from "@c/survey/results/SelectColor";
    // Displays a toggle button for a single data series e.g."my organisation"
	// @group Analysis
    export default {
		name: "SeriesSelectionPanel", 
		props: {
            // a unique identifier for the series
            id: { type: String },
            // the color for the dataseries
            color: { type: String },
            // A display name for the series 
            label: { type: String }
		},
        components: {
            SelectColor
        },
	}
</script>