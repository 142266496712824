<template>
	<v-btn-toggle v-model="type" class="mr-4 ml-3">
		<v-btn icon v-for="type in chartTypes" :key="type.name" :value="type.name">
			<v-icon>{{type.icon}}</v-icon>
		</v-btn>
	</v-btn-toggle>
</template>

<style lang="less">
	.chartWrapper {
		width: 100%;
		position: relative;
	}
</style>

<script type="text/javascript">
	// Displays a group of icon buttons to select the chart type - required toggle button
	// @group Analysis
	export default {
		name: "ChartType", 
		data: () => {
			return {
				type: "BarChart"
			}
		},
		computed: {
			chartTypes(){
				return [
					{
						name: "BarChart", 
						icon: "mdi-chart-bar", 
						title: this.$t("results.bar_chart")
					},
					{
						name: "LineChart", 
						icon: "mdi-chart-line", 
						title: this.$t("results.line_chart")
					},
					{
						name: "AreaChart", 
						icon: "mdi-chart-areaspline-variant", 
						title: this.$t("results.area_chart")
					},
					{
						name: "HBarChart", 
						icon: "mdi-chart-gantt", 
						title: this.$t("results.hbar_chart"),
					},
				]
			},
		},
		watch: {
			type(v) {
				// emitted on click, outputs the selected chart type name
				this.$emit("change", v );
			}, 
		}

	}
// </script>"