<template>
	<v-menu>
		<template v-slot:activator="{ on }">
			<v-btn small class="ma-2" v-on="on" icon
				><v-icon small>mdi-format-color-fill</v-icon></v-btn
			>
		</template>
		<v-color-picker
			@input="changeColor"
			v-model="value"
		></v-color-picker>
	</v-menu>
</template>

<style lang="less"></style>

<script type="text/javascript">
// Renders a vuetify colorpicker to allow users to select a new color for a data series
// @group Analysis
export default {
	name: "SelectColour",
	props: {
		// the initial colour
		color: { type: String },
	},
	data: () => {
		return {
			value: false,
		};
	},
	created() {
		this.value = this.color;
	},
	methods: {
		changeColor(v){
			// Emitted when new color is selected 
			this.$emit('change', v.hex || v);
		}
	}
};
//
</script>
"
