<template>
	<v-card flat v-if="haveData" width="100%">
		<v-card-title>
			{{ $t("results.handlungsbedarf.title") }}
			<v-spacer></v-spacer>
			<export
				icon
				:headers="exportColumns"
				:rows="items"
				name="handlungsbedarf"
			></export>
		</v-card-title>
		<v-card-text>
			<p>
				{{ $t("results.handlungsbedarf.intro") }}
			</p>
			<v-data-table
				id="table"
				:headers="headers"
				:items="items"
				:expanded.sync="expanded"
				item-key="goal"
				:show-expand="showExpand"
				@update:sort-by="sorted"
			>
				<template v-slot:header.relevanz>
					<v-menu open-on-hover offset-x offset-y>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" icon small
								><v-icon small>mdi-information</v-icon></v-btn
							>
						</template>
						<v-card class="pa-2 pt-4">
							<p>Absolut nicht relevant</p>
							<p>Eher nicht relevant</p>
							<p>Eher relevant</p>
							<p>Absolut relevant</p>
						</v-card>
					</v-menu>
					Relevanz
				</template>
				<template v-slot:header.umsetzung>
					<v-menu open-on-hover offset-x offset-y>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" icon small
								><v-icon small>mdi-information</v-icon></v-btn
							>
						</template>
						<v-card class="pa-2 pt-4">
							<p>0%: Umsetzung nicht absehbar</p>
							<p>25%: Umsetzung definiert und beschrieben</p>
							<p>50%: Umsetzung entschieden und geplant</p>
							<p>75%: Umsetzung in Arbeit</p>
							<p>100%: Umsetzung abgeschlossen</p>
						</v-card>
					</v-menu>
					Umsetzung
				</template>
				<template v-slot:header.dringlichkeit>
					<v-menu open-on-hover offset-x offset-y>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" icon small
								><v-icon small>mdi-information</v-icon></v-btn
							>
						</template>
						<v-card class="pa-2 pt-4">
							<p>1 - 5 Jahre</p>
						</v-card>
					</v-menu>
					Dringlichkeit
				</template>
				<template v-slot:header.handlungsbedarf>
					<v-menu open-on-hover offset-x offset-y>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" icon small
								><v-icon small>mdi-information</v-icon></v-btn
							>
						</template>
						<v-card class="pa-2 pt-4">
							<p>0: kein handlungsbedarf</p>
							<p>5: maximaler Handlungsbedarf</p>
						</v-card>
					</v-menu>
					Handlungsbedarf
				</template>
				<template v-slot:expanded-item="{ headers, item }">
					<td :colspan="headers.length">
						<table width="100%">
							<tr
								v-for="row in singleItems.filter(
									(a) => a.goal == item.goal
								)"
								:key="row.response"
							>
								<td class="pa-1" :width="widths[0]"></td>
								<td class="pa-1" :width="widths[1]">
									<span class="subtitle-2"
										>{{ row.goalGroup }}, {{ row.department }}</span
									>
								</td>
								<td class="pa-1" :width="widths[2]">
									{{ row.relevanz }}
								</td>
								<td class="pa-1" :width="widths[3]">
									{{ row.umsetzung }}%
								</td>
								<td class="pa-1" :width="widths[4]">
									{{ row.dringlichkeit }}J.
								</td>
								<td class="pa-1" :width="widths[4]">
									<v-avatar color="accent" size="42">
										<span class="small">
											{{ row.handlungsbedarf }}
										</span>
									</v-avatar>
								</td>
							</tr>
						</table>
					</td>
				</template>
				<template v-slot:item.goal="{ item }">
					<span class="subtitle-1 font-weight-light">{{
						item.goalName
					}}</span>
					<span v-if="sortBy != 'goal'">
						<br />
						<span class="subtitle-2"
							>{{ item.goalGroup }}, {{ item.department }}</span
						>
					</span>
				</template>
				<template v-slot:item.handlungsbedarf="{ item }">
					<v-avatar color="accent" size="42">
						<span class="small">
							{{ item.handlungsbedarf }}
						</span>
					</v-avatar>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<style lang="less">
.v-data-table
	> .v-data-table__wrapper
	tbody
	tr.v-data-table__expanded__content {
	box-shadow: none;
}
</style>

<script type="text/javascript">
import Vuex from "vuex";
import Export from "@c/downloads/Export";
// Renders the list of goals included in the handlungsbedarf calculation 
// @group Analysis 
export default {
	name: "Handlungsbedarf",
	props: {
		// A list of datapoints included in the selection
		data: { type: Object },
	},
	data: () => {
		return {
			expanded: [],
			sortBy: "goal",
			widths: [0, 0, 0, 0, 0, 0, 0],
		};
	},
	components: {
		Export,
	},
	computed: {
		...Vuex.mapState({
			assessment: (state) => state.assessment.data,
			responseData: (state) => state.responseData.data,
			departments: (state) => state.departments.data,
			sectionParts: (state) => state.sectionParts.data,
			goalGroups: (state) => state.goalGroups.data,
			goals: (state) => state.goals.data,
		}),
		showExpand() {
			return this.sortBy == false || this.sortBy == "goal";
		},
		responseMap() {
			return this.$store.getters.responseMap;
		},
		haveData() {
			return Object.keys(this.data).length;
		},
		headers() {
			return [
				{ value: "goal", text: this.$t("goals.nav.single") },
				{ value: "relevanz", text: "Relevanz" },
				{ value: "umsetzung", text: "Umsetzung" },
				{ value: "dringlichkeit", text: "Dringlichkeit" },
				{ value: "handlungsbedarf", text: "Handlungsbedarf" },
			];
		},
		responses() {
			var self = this;
			return Object.keys(self.data).map((id) => {
				var response = self.responseData[id];
				return {
					id: id,
					goal: response.goal,
					sectionResponse: response.sectionResponse,
					part: response.part,
					department: response.department,
					relevanz: self.mwsurveyutilities.relevanz(response),
					dringlichkeit: self.mwsurveyutilities.dringlichkeit(response),
					umsetzung: self.mwsurveyutilities.umsetzung(response),
					handlungsbedarf: self.data[id],
				};
			});
		},
		groupedItems() {
			const self = this;
			return self.goalsInThisView.map((goal) => {
				let responses = self.responses.filter(
					(response) => response.goal == goal
				);
				let handlungsbedarf = responses.map(
					(response) => response.handlungsbedarf
				);
				let average = self.mwsurveyutilities.averageScore(handlungsbedarf);
				return {
					goal: goal,
					goalName: self.goals[goal] ? self.goals[goal].name : "",
					handlungsbedarf: average,
				};
			});
		},
		singleItems() {
			const self = this;
			return self.responses
				.filter((r) => self.goals[r.goal])
				.map((r) => {
					r.goalName = self.goals[r.goal].name || "";
					r.goalGroup = self.labelMap[r.part];
					r.department = self.labelMap[r.sectionResponse];
					return r;
				});
		},
		items() {
			const self = this;
			if (self.sortBy == "goal") {
				return self.groupedItems;
			} else {
				return self.singleItems;
			}
		},
		goalsInThisView() {
			const self = this;
			var goals = self.responses.map((response) => response.goal);
			return goals
				.filter((g, i) => goals.indexOf(g) == i)
				.filter((goal) => self.goals[goal]);
		},
		labelMap() {
			const self = this;
			var labels = {};
			Object.keys(self.responseMap).forEach((d) => {
				labels[self.responseMap[d].id] = self.departments[d].name;
			});
			Object.values(self.sectionParts).forEach((p) => {
				var g = self.goalGroups[p.goalGroup];
				labels[p.id] = g.name;
			});
			return labels;
		},
		tableColumns() {
			return {
				[this.$t("goals.nav.single")]: "goalName",
				[this.$t("goal_groups.nav.single")]: "goalGroup",
				[this.$t("departments.nav.single")]: "department",
				Relevanz: "relevanz",
				Umsetzung: "umsetzung",
				Dringlichkeit: "dringlichkeit",
				Handlungsbedarf: "handlungsbedarf",
			};
		},
		exportColumns() {
			var columns = this.tableColumns;
			return Object.keys(columns).map((c) => {
				return { field: columns[c], label: c };
			});
		},
	},
	methods: {
		sorted(value) {
			this.expanded = [];
			this.sortBy = value[0] || false;
		},
		calculateWidths() {
			var widths = [];
			let container = document.getElementById("table");
			let header = container.querySelector("thead tr");
			header.querySelectorAll("th").forEach((th) => {
				widths.push(th.clientWidth);
			});
			this.widths = widths;
		},
	},
	watch: {
		expanded() {
			this.calculateWidths();
		},
	},
};
//
</script>
"
