<template>
	<v-expansion-panel class="no-padding">
		<v-list-item>
			<v-checkbox v-model="checked" :indeterminate="some"></v-checkbox>
			<v-expansion-panel-header>
				{{ department.name }}
				<v-badge
					color="green"
					:content="count || '0'"
					class="mr-7 mt-3"
				></v-badge>
			</v-expansion-panel-header>
		</v-list-item>
		<v-expansion-panel-content>
			<v-item
				v-for="p in orderedPartIds"
				:key="p"
				v-slot:default="{ active, toggle }"
				:value="p"
			>
				<div class="toggleChip" :class="{ active: active }" @click="toggle">
					{{ partName(p) }}
				</div>
			</v-item>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<style lang="less">
.v-expansion-panel.no-padding {
	.v-expansion-panel-header,
	.v-expansion-panel-content__wrap {
		padding-left: 0px !important;
	}
} 
</style>

<script type="text/javascript">
// Displays a list of available data points to be included or excluded from the visualisation
// @group Analysis
export default { 
	name: "DataSelectionPanel",
	props: {
		// the id of the department
		id: { type: String }, 
		// An array of initially selected datapoints
		selected: { type: Array, default: () => [] },
	},
	data: () => {
		return {
			checked: false,
		};
	},
	inject: ["makePartId", "splitPartId"],
	computed: {
		goalGroups() {
			return this.$store.state.goalGroups.data;
		},
		sectionParts() {
			return this.$store.state.sectionParts.data;
		},
		department() {
			return this.$store.state.departments.data[this.id];
		},
		partOrder() {
			return this.$store.getters.partOrder[this.id];
		},
		orderedPartIds() {
			const self = this;
			return self.partOrder.map( p =>{
				return self.makePartId(self.id, p )
			} )
		},
		count() {
			var self = this;
			var included = self.orderedPartIds.filter((a) => self.selected.includes(a));
			return included.length || 0;
		},
		all() {
			return this.count == this.orderedPartIds.length;
		},
		some() {
			return this.count > 0 && this.count < this.orderedPartIds.length;
		},
	},
	watch: {
		checked(value) {
			this.toggle(value);
		},
		all(value) {
			if (!value == this.checked) {
				this.checked = value;
			}
		},
	},
	methods: {
		toggle(value) {
			// Fired when a user changes the selection
			this.$emit("select", this.id, value);
		},
		partName(id) {
			let partId = this.splitPartId(id).part;
			var part = this.sectionParts[partId];
			var gg = this.goalGroups[part.goalGroup];
			return gg.name || "";
		},
	},
};
//
</script>
"
