<template>
	<v-sheet class="pt-4">
		<v-row>
			<div class="chartWrapper">
				<v-progress-circular
					indeterminate
					v-if="loading"
				></v-progress-circular>
				<v-component
					v-if="!loading"
					:options="options"
					:height="height"
					:is="type"
					:chart-data="dataCollection"
				></v-component>
			</div>
		</v-row>
	</v-sheet>
</template>

<style lang="less">
.chartWrapper {
	width: 100%;
	position: relative;
}
</style>

<script type="text/javascript">
// Displays a single chart 
// Uses the chart.js lib
// See documentation: https://www.chartjs.org/docs/2.9.4/ and https://vue-chartjs.org/
// NB Latest chart.js is incompatible with vue-chart.js. Do not update above 2.94
// @group Analysis
export default {
	name: "Chart",
	props: {
		// What type of chart  options:  ["BarChart", "HBarChart", "LineChart", "AreaChart"]
		type: { type: String, default: "BarChart" }, 
		// Whether the data is still loading
		loading: { type: Boolean, default: false },
		// The chart data, formatted per chartjs documentation
		data: { type: Array },
		// The chart labels, formatted per chartjs documentation
		labels: { type: Array },
		// Additional options, per chartjs documentation
		options: { type: Object },
	},
	data: () => {
		return {
			height: 200,
		};
	},
	components: {
		BarChart: () => import("@a/charts/BarChart.js"),
		HBarChart: () => import("@a/charts/HBarChart.js"),
		LineChart: () => import("@a/charts/LineChart.js"),
		AreaChart: () => import("@a/charts/AreaChart.js"),
	},
	computed: {
		formattedLabels(){
			return this.labels.map( label => {
				if( label.length > 22) {
					return label.replace(/[\s\S]{1,22}(?!\S)/g, '$&\n').split("\n")
				}
				return label;
			} )
		},
		dataCollection() {
			var data = this.data;
			return {
				labels: this.formattedLabels,
				datasets: data,
			};
		},
	},
};
//
</script>
