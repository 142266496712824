<template>
    <v-btn icon @click="save"><v-icon>mdi-download</v-icon></v-btn>
</template>

<script type="text/javascript">
	// Renders a button which outputs a PNG file of a given canvas
	// @group Downloads
	export default {
        name: "SaveImage", 
		props: {
			// the ID of the canvas (nb: the vue-ish solution here is obviously refs but as this button is shown only when the visualization type is set to chart, refs provide an inconsistent targe)
            id: { type: String, required: true },
			// the output file name
			filename: {type: String, default: "Image.png"}
		},
		methods: {
			// @vuese 
			// Fired when the button is clicked, creates the png "image" and downloads it
            save() {
				var a = document.createElement('a');
				var canvas = document.querySelector(`#${this.id} canvas`);
				var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  
				a.href = image;
				a.download = this.filename;
				document.body.appendChild(a);
				a.click();
			},
		}
	}
</script>